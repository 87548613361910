<template>
  <svg viewBox="0 0 104 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 28.069V8.021H5.52811V28.069H0Z" fill="#00785F" />
    <path
      d="M25.3065 15.8446C29.0013 15.8446 31.7381 17.8784 31.7381 21.9449C31.7381 25.2962 29.2748 28.0744 25.3065 28.0744H9.76562V8.021H29.335V12.947H15.2937V15.8446H25.3065ZM24.6228 23.435C25.6346 23.435 26.2099 23.0009 26.2099 21.9449C26.2099 20.8889 25.6346 20.4852 24.6228 20.4852H15.2664V23.435H24.6228Z"
      fill="#00785F"
    />
    <path d="M34.5645 28.069V8.021H40.0882V28.069H34.5645Z" fill="#00785F" />
    <path
      d="M61.8272 20.3647C61.3896 22.3996 58.6847 23.601 55.2753 23.601C51.0871 23.601 48.7124 21.3957 48.7124 18.0444C48.7124 14.6932 51.0925 12.489 55.2753 12.489C58.6661 12.489 61.3798 13.6828 61.8173 15.7252H67.317C66.781 10.7547 62.3249 7.56299 55.2753 7.56299C47.7488 7.56299 43.1777 11.7152 43.1777 18.0444C43.1777 24.3737 47.7488 28.527 55.2753 28.527C62.3249 28.527 66.781 25.3352 67.317 20.3647H61.8272Z"
      fill="#00785F"
    />
    <path
      d="M103.691 18.6851L89.9736 14.2095L67.3164 36.0905H86.1474L103.691 18.6851Z"
      fill="#FF612F"
    />
    <path
      d="M103.691 17.4054L89.1587 11.3952L86.3957 12.9027C85.2643 13.5196 83.9617 13.7555 82.6836 13.5751C81.4055 13.3947 80.2209 12.8076 79.3078 11.9021L67.3164 0H86.1474L103.691 17.4054Z"
      fill="#FF612F"
    />
  </svg>
</template>
