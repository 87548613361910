<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="() => void 0">
      <AppDialogsCommonBackdrop />

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full justify-center text-center items-center p-0"
        >
          <AppDialogsCommonPanelTransition>
            <DialogPanel
              class="flex flex-col gap-4 fixed transform overflow-hidden rounded-0 bg-white px-[30px] py-[26px] text-left transition-all min-h-full sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:relative sm:rounded-lg"
            >
              <div class="">
                <div class="flex items-start justify-between">
                  <DialogTitle class="text-xl">
                    Зворотний зв'язок
                  </DialogTitle>
                  <div class="ml-3 flex h-7 items-center">
                    <AppDialogsCommonCloseButton @click="open = false" />
                  </div>
                </div>
              </div>
              <div class="text-xs leading-4">
                Ми працюємо з 9:30 до 18:00, але навіть якщо запит на зворотний
                дзвінок буде залишений у не робочий час - Ваш запит не
                залишиться без уваги. Ми зв'яжемося з Вами за першої ж нагоди.
              </div>
              <Form
                :validation-schema="callbackValidationSchema"
                class="flex flex-col gap-4"
                @submit="submit"
              >
                <AppFormInput
                  type="tel"
                  placeholder="+38 (0__) ___-__-__*"
                  name="phoneNumber"
                />
                <AppFormInput type="email" placeholder="Email*" name="email" />
                <AppFormTextarea placeholder="Коментар*" name="message" />
                <button
                  type="submit"
                  class="btn btn-primary w-auto max-w-max px-[34px] uppercase"
                  aria-label="Відправити"
                >
                  Відправити
                </button>
              </Form>
            </DialogPanel>
          </AppDialogsCommonPanelTransition>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { Form } from 'vee-validate'
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionRoot
} from '@headlessui/vue'
import { callbackValidationSchema, formatPhone } from '~/utils/helpers/validate'

const emits = defineEmits(['update:modelValue'])
const open = ref(false)

const submit = values => {
  const { show: showLoader, hide: hideLoader } = useLoader()
  values.phoneNumber = formatPhone(values.phoneNumber)
  useApi()
    .contactRequests.sendRequest(values, {
      onRequest: () => showLoader(),
      onSuccess: () => {
        open.value = false
      },
      onFinally: () => hideLoader(),
      successNotification: 'Ваш запит успішно відправлено!'
    })
    .catch(() => {})
}

onMounted(() => {
  emits('update:modelValue', {
    isOpen: open,
    open: () => {
      open.value = true
    },
    close: () => {
      open.value = false
    }
  })
})
</script>
