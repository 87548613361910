<template>
  <div class="change-success-container">
    <div class="change-success-section">
      <CheckIcon class="section-icon" />
      <h2 class="section-title">
        {{ t('Пароль успішно змінено') }}
      </h2>
      <button class="next-button" @click="clickHandler">
        {{ t('Увійти до кабінету') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { CheckIcon } from '@heroicons/vue/24/outline'

const goTo = inject('goTo')
const { t } = useI18n()
const clickHandler = () => {
  goTo('auth')
}
</script>

<style lang="postcss" scoped>
.change-success-container {
  @apply flex p-3 w-full h-full md:w-[623px];
}
.change-success-section {
  @apply flex flex-col items-center m-auto gap-6 md:gap-5 max-w-[290px] md:max-w-[267px];
}
.section-icon {
  @apply text-orange h-12 md:h-7;
}
.section-title {
  @apply text-xl font-bold;
}
.next-button {
  @apply btn btn-primary text-xs uppercase px-6 py-3 w-full md:w-auto;
}
</style>
