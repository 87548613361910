<template>
  <div class="bg-gray pt-5 pb-20 md:py-0">
    <div
      class="flex flex-col items-center justify-between h-auto container md:flex-row md:h-16"
    >
      <div>
        <IconsLogoExtended />
      </div>
      <p
        class="text-sm text-green-swamp leading-3 py-[25px] md:ml-[69px] md:py-0"
      >
        {{ t('Всі права захищені') }} &copy; 2008-{{ currentYear }}
      </p>
      <div
        class="text-sm text-center leading-3 md:mr-[69px] pb-[25px] md:py-0 md:ml-auto"
      >
        {{ t('made_in') }}
        <a
          class="link text-primary"
          href="https://urancompany.com/"
          rel="nofollow"
          target="_blank"
        >
          Uran</a>
      </div>
      <div class="flex items-center">
        <div class="mr-5">
          <IconsVisa />
        </div>
        <div class="mr-5">
          <IconsMastercard />
        </div>
        <div>
          <IconsNovaPoshta />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const { t } = useI18n()
const currentYear = computed(() => new Date().getFullYear())
</script>
