<template>
  <div class="sign-up-container">
    <Form
      id="sign-up-form"
      :validation-schema="signUpValidationSchema"
      @submit="submit"
    >
      <span class="text-xs md:text-sm">
        {{ t('Зареєструйтесь та почніть заощаджувати') }}
      </span>
      <AppFormInput
        id="last-name-input"
        v-model="form.lastName"
        name="lastName"
        type="text"
        :placeholder="t('Прізвище') + '*'"
        input-class="h-12 md:h-[42px]"
      />
      <AppFormInput
        id="first-name-input"
        v-model="form.firstName"
        name="firstName"
        type="text"
        :placeholder="t('Ім\'я') + '*'"
        input-class="h-12 md:h-[42px]"
      />
      <AppFormInput
        id="middle-name-input"
        v-model="form.patronymic"
        name="patronymic"
        type="text"
        :placeholder="t('По батькові') + '*'"
        input-class="h-12 md:h-[42px]"
      />
      <AppFormInput
        id="phone-input"
        v-model="form.phoneNumber"
        name="phoneNumber"
        type="tel"
        placeholder="+38 (0__) ___-__-__*"
        input-class="h-12 md:h-[42px]"
      />
      <AppFormInput
        id="email-input"
        v-model="form.email"
        name="email"
        type="text"
        placeholder="Email*"
        input-class="h-12 md:h-[42px]"
      />
      <AppFormInput
        id="password-input"
        v-model="form.password"
        name="password"
        type="password"
        :placeholder="t('Пароль') + '*'"
        input-class="h-12 md:h-[42px]"
      />
      <AppFormInput
        id="confirm-password-input"
        v-model="form.passwordConfirmation"
        name="passwordConfirmation"
        type="password"
        :placeholder="t('Повторіть пароль') + '*'"
        input-class="h-12 md:h-[42px]"
      />
      <button
        type="submit"
        class="submit-button"
        :aria-label="t('Зареєструватись')"
      >
        {{ t('Зареєструватись') }}
      </button>
      <span class="text-xs text-center">
        {{ t('Реєструючись, Ви погоджуєтесь з') }}
        <NuxtLink to="#" class="underline">
          {{ t('політикою конфіденційності') }}
        </NuxtLink>
      </span>
    </Form>
    <div class="middle-section">
      <span class="my-0 md:my-auto">{{ t('або') }}</span>
    </div>
    <div class="socials-section">
      <span class="socials-section-title">{{
        t('Зареєструйтесь через соц мережі')
      }}</span>
      <div class="socials-section-buttons">
        <AppSocialsGoogleSignInButton />
        <AppSocialsFacebookSignInButton />
      </div>
    </div>
  </div>
</template>

<script setup>
import { Form } from 'vee-validate'
import { formatPhone, signUpValidationSchema } from '~~/utils/helpers/validate'
import { useAuthStore } from '~~/stores/auth'
const { t } = useI18n()
const { $toast } = useNuxtApp()
const authStore = useAuthStore()

const closeDialog = inject('closeDialog')
const loading = inject('loading')

const form = reactive({
  lastName: '',
  firstName: '',
  patronymic: '',
  phoneNumber: '',
  email: '',
  password: '',
  passwordConfirmation: ''
})

provide('isRemember', ref(true))
provide('redirect', ref(true))
const logInAfterRegister = () => {
  loading.value = true
  const formData = { username: form.email, password: form.password }
  const options = {
    onSuccess: () => closeDialog(),
    onFinally: () => (loading.value = false),
    redirect: true
  }
  authStore.login(formData, options)
}

const submit = (values, actions) => {
  const preparedForm = {
    ...values,
    phoneNumber: formatPhone(form.phoneNumber)
  }

  authStore.register(preparedForm, {
    onRequest: () => {
      loading.value = true
    },
    onSuccess: () => {
      $toast.success(t('Реєстрація успішна'))
      logInAfterRegister()
    },
    onError: error => {
      actions.setErrors(error.getViolationsObject())
    },
    onFinally: () => {
      loading.value = false
    }
  })
}
</script>

<style lang="postcss" scoped>
.sign-up-container {
  @apply flex gap-4 flex-col items-center md:gap-[30px] md:flex-row md:items-stretch w-full max-w-sm md:max-w-none md:w-fit mx-auto md:mx-0;
}
#sign-up-form {
  @apply flex flex-col gap-4 w-full md:w-[266px];
}
.submit-button {
  @apply btn btn-primary w-auto uppercase text-xs;
}
.middle-section {
  @apply flex md:h-52 h-fit text-xs md:text-sm;
}
.socials-section {
  @apply flex flex-col w-full md:w-48;
}
.socials-section-title {
  @apply text-xs mb-4 text-center md:text-left md:text-sm;
}
.socials-section-buttons {
  @apply flex flex-row gap-4 md:flex-col;
}
</style>
