<template>
  <div ref="authRef" class="flex flex-col gap-5">
    <h2 class="text-xl font-bold">
      {{ t('Особистий кабінет') }}
    </h2>
    <TabGroup>
      <TabList class="tab-list">
        <Tab
          v-for="tab in tabs"
          :key="tab.label"
          v-slot="{ selected }"
          as="template"
        >
          <span
            :class="[
              selected ? 'selected-tab' : 'not-selected-tab',
              'tab-title'
            ]"
          >
            {{ tab.label }}
          </span>
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel v-for="(tab, idx) in tabs" :key="idx">
          <component :is="tab.component" :loading="loading" />
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script setup>
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
const { t } = useI18n()

const tabs = [
  {
    label: t('Вхід'),
    component: resolveComponent('AppDialogsAuthSignIn')
  },
  {
    label: t('Реєстрація'),
    component: resolveComponent('AppDialogsAuthSignUp')
  }
]

const loading = ref(false)
provide('loading', loading)

const authRef = ref(null)
const { show: showLoader, hide: hideLoader } = useLoader(authRef.value)

onMounted(() => {
  const watchHandler = v => (v ? showLoader() : hideLoader())
  watch(loading, watchHandler, { immediate: true })
})
</script>

<style lang="postcss" scoped>
.tab-list {
  @apply flex gap-8 justify-center border-b border-gray;
}
.selected-tab {
  @apply border-orange text-orange;
}
.not-selected-tab {
  @apply border-transparent text-green-swamp;
}
.tab-title {
  @apply border-b-2 pb-[10px] text-sm cursor-pointer;
}
</style>
