<template>
  <AppDialogsAuthDialogWrapper :open="showDialog" @close="closeDialog">
    <component :is="components[currentComponent]" />
  </AppDialogsAuthDialogWrapper>
</template>

<script setup>
import { useAuthStore } from '~~/stores/auth'

const route = useRoute()
const authStore = useAuthStore()
const showDialog = computed(() => authStore.isShownDialog)
const closeDialog = () => {
  authStore.hideAuthDialog()
  setTimeout(() => {
    setComponent('auth')
  }, 500)
  if ('login' in route.query) {
    const query = { ...route.query }
    delete query.login
    useRouter().replace({ path: route.path, query })
  }
}

const components = {
  auth: resolveComponent('AppDialogsAuthWrapper'),
  passwordRecovery: resolveComponent('AppDialogsAuthPasswordRecovery'),
  passwordResetSuccess: resolveComponent('AppDialogsAuthPasswordResetSuccess'),
  passwordChangeSuccess: resolveComponent('AppDialogsAuthPasswordChangeSuccess')
}
const currentComponent = ref('auth')
const setComponent = v => {
  currentComponent.value = v
}

onMounted(() => {
  if ('login' in route.query) {
    authStore.showAuthDialog()
  }
})

provide('goTo', setComponent)
provide('closeDialog', closeDialog)
</script>
