<template>
  <svg
    width="166"
    height="32"
    viewBox="0 0 166 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 24.5688V7.021H4.8387V24.5688H0Z" fill="#00785F" />
    <path
      d="M22.1513 13.869C25.3854 13.869 27.7808 15.6491 27.7808 19.2084C27.7808 22.1418 25.6248 24.5736 22.1513 24.5736H8.54858V7.021H25.6774V11.3327H13.3873V13.869H22.1513ZM21.5529 20.5127C22.4385 20.5127 22.9421 20.1327 22.9421 19.2084C22.9421 18.2842 22.4385 17.9308 21.5529 17.9308H13.3633V20.5127H21.5529Z"
      fill="#00785F"
    />
    <path d="M30.2539 24.5688V7.021H35.0888V24.5688H30.2539Z" fill="#00785F" />
    <path
      d="M54.1161 17.8253C53.7331 19.6064 51.3655 20.658 48.3813 20.658C44.7154 20.658 42.6369 18.7278 42.6369 15.7944C42.6369 12.8611 44.7202 10.9318 48.3813 10.9318C51.3492 10.9318 53.7245 11.9767 54.1075 13.7644H58.9213C58.4521 9.41382 54.5517 6.62012 48.3813 6.62012C41.7934 6.62012 37.7924 10.2545 37.7924 15.7944C37.7924 21.3343 41.7934 24.9697 48.3813 24.9697C54.5517 24.9697 58.4521 22.176 58.9213 17.8253H54.1161Z"
      fill="#00785F"
    />
    <path
      d="M90.7596 16.3545L78.7528 12.437L58.9213 31.5892H75.4038L90.7596 16.3545Z"
      fill="#FF612F"
    />
    <path
      d="M90.7596 15.2348L78.0396 9.97413L75.6212 11.2936C74.6309 11.8335 73.4907 12.0401 72.372 11.8821C71.2533 11.7242 70.2164 11.2103 69.4172 10.4177L58.9213 0H75.4038L90.7596 15.2348Z"
      fill="#FF612F"
    />
    <path
      d="M100.524 11.2302H101.836C101.831 11.3881 101.871 11.544 101.951 11.6775C102.032 11.8111 102.149 11.9161 102.287 11.9791C102.586 12.1385 103.055 12.2182 103.692 12.2182C104.33 12.2182 104.798 12.1401 105.098 11.984C105.233 11.9206 105.346 11.8165 105.424 11.685C105.502 11.5535 105.54 11.4007 105.535 11.246C105.535 10.9065 105.41 10.6615 105.16 10.5129C104.91 10.3643 104.497 10.2885 103.922 10.2885H102.574V9.08806H103.971C104.498 9.08806 104.878 9.01688 105.11 8.87453C105.341 8.73218 105.457 8.50258 105.456 8.18573C105.46 8.04271 105.422 7.90197 105.346 7.78273C105.271 7.6635 105.162 7.57159 105.035 7.51956C104.754 7.38081 104.318 7.31193 103.723 7.31193C103.129 7.31193 102.679 7.38081 102.398 7.52349C102.27 7.57817 102.161 7.67277 102.086 7.79453C102.011 7.91629 101.973 8.05936 101.977 8.20442H100.665C100.665 7.44215 100.903 6.88914 101.378 6.5454C101.854 6.20165 102.635 6.03011 103.722 6.03076C104.799 6.03076 105.576 6.18689 106.053 6.49915C106.531 6.81141 106.768 7.32079 106.767 8.0273C106.767 8.81057 106.479 9.36161 105.904 9.68043C106.529 9.99203 106.842 10.5677 106.842 11.4074C106.842 12.1355 106.589 12.6659 106.084 12.9985C105.579 13.3311 104.779 13.4974 103.683 13.4974C102.587 13.4974 101.786 13.317 101.281 12.9562C100.775 12.5954 100.523 12.0201 100.524 11.2302Z"
      fill="#00785F"
    />
    <path
      d="M108.937 13.4903H107.416L110.63 6.03076H112.243L115.457 13.4974H113.936L113.357 12.1322H109.518L108.937 13.4903ZM111.436 7.60379L110.12 10.7176H112.752L111.436 7.60379Z"
      fill="#00785F"
    />
    <path
      d="M118.903 13.4903V6.03076H120.37V8.95687H121.493L123.782 6.03076H125.56L122.706 9.6561L125.795 13.4954H123.979L121.504 10.3695H120.37V13.4974L118.903 13.4903Z"
      fill="#00785F"
    />
    <path
      d="M126.37 13.4974V6.03076H130.1C131.007 6.03076 131.666 6.22838 132.075 6.62361C132.484 7.01884 132.688 7.65376 132.687 8.52838C132.687 9.40368 132.483 10.0406 132.075 10.4392C131.666 10.8378 131.008 11.0371 130.1 11.0371H127.847V13.4974H126.37ZM127.847 9.62318H130.018C130.437 9.62318 130.742 9.53531 130.935 9.3616C131.127 9.18789 131.222 8.90813 131.222 8.52333C131.222 8.13854 131.123 7.8709 130.935 7.69618C130.746 7.52146 130.439 7.4346 130.018 7.4346H127.847V9.62318Z"
      fill="#00785F"
    />
    <path
      d="M137 6.03076C138.303 6.03076 139.25 6.32588 139.843 6.91613C140.436 7.50638 140.731 8.45733 140.728 9.76898C140.728 11.0728 140.433 12.0204 139.843 12.612C139.253 13.2036 138.305 13.4987 137 13.4974C135.688 13.4974 134.737 13.2022 134.147 12.612C133.557 12.0218 133.262 11.0741 133.262 9.76898C133.262 8.45733 133.557 7.50638 134.147 6.91613C134.737 6.32588 135.688 6.03076 137 6.03076ZM135.264 7.9589C134.903 8.33731 134.723 8.9387 134.723 9.76308C134.723 10.5875 134.903 11.1869 135.264 11.5614C135.624 11.9365 136.203 12.1237 137 12.1231C137.798 12.1224 138.376 11.9352 138.735 11.5614C139.096 11.1875 139.276 10.5881 139.276 9.76308C139.276 8.93805 139.096 8.33665 138.735 7.9589C138.375 7.58114 137.797 7.39226 137 7.39226C136.203 7.39226 135.624 7.58114 135.264 7.9589Z"
      fill="#00785F"
    />
    <path
      d="M141.303 13.4903V6.03076H142.771V8.95687H143.893L146.18 6.03076H147.96L145.106 9.6561L148.195 13.4954H146.374L143.903 10.3695H142.771V13.4974L141.303 13.4903Z"
      fill="#00785F"
    />
    <path
      d="M101.968 25.5588H100.524V22.6137H101.486C101.686 22.4607 101.849 22.1228 101.975 21.6002C102.102 21.0775 102.2 20.1078 102.269 18.6909L102.367 16.9435H107.964V22.6108H109.139V25.556H107.678V23.9313H101.968V25.5588ZM106.51 22.6137V18.2612H103.716L103.696 18.7703C103.604 20.5945 103.387 21.8757 103.044 22.6137H106.51Z"
      fill="#00785F"
    />
    <path
      d="M113.161 16.9435C114.365 16.9435 115.241 17.2159 115.788 17.7607C116.335 18.3054 116.607 19.1831 116.606 20.3937C116.606 21.597 116.333 22.472 115.788 23.0186C115.243 23.5652 114.367 23.8376 113.161 23.8357C111.95 23.8357 111.073 23.5634 110.531 23.0186C109.989 22.4738 109.716 21.5989 109.713 20.3937C109.713 19.1831 109.986 18.3054 110.531 17.7607C111.076 17.2159 111.953 16.9435 113.161 16.9435ZM111.556 18.724C111.223 19.0726 111.057 19.6274 111.057 20.3883C111.057 21.1491 111.223 21.7027 111.556 22.0489C111.89 22.3939 112.424 22.5664 113.159 22.5664C113.895 22.5664 114.429 22.3939 114.763 22.0489C115.096 21.7027 115.262 21.1491 115.263 20.3883C115.263 19.6274 115.097 19.0726 114.763 18.724C114.43 18.3753 113.896 18.201 113.159 18.201C112.423 18.201 111.888 18.3753 111.556 18.724Z"
      fill="#00785F"
    />
    <path
      d="M120.626 23.8358V16.9435H127.518V23.8358H126.118V18.2474H122.036V23.8358H120.626Z"
      fill="#00785F"
    />
    <path
      d="M128.667 23.8358V16.9435H132.397C133.304 16.9435 133.962 17.1257 134.372 17.4902C134.782 17.8547 134.986 18.4402 134.985 19.2468C134.985 20.054 134.781 20.6414 134.372 21.009C133.964 21.3766 133.306 21.5604 132.397 21.5604H130.144V23.8339L128.667 23.8358ZM130.144 20.263H132.313C132.733 20.263 133.039 20.1825 133.23 20.0217C133.422 19.8606 133.518 19.6035 133.518 19.2487C133.518 18.8938 133.419 18.647 133.23 18.4859C133.042 18.3247 132.735 18.2446 132.313 18.2446H130.144V20.263Z"
      fill="#00785F"
    />
    <path
      d="M137.083 23.8358H135.559V16.9435H137.083V21.8063L141.513 16.9435H143.026V23.8358H141.513V18.973L137.083 23.8358Z"
      fill="#00785F"
    />
    <path
      d="M144.175 23.8358V16.9435H149.344V18.2474H145.598V23.8358H144.175Z"
      fill="#00785F"
    />
    <path
      d="M153.368 16.9435C154.572 16.9435 155.447 17.2159 155.993 17.7607C156.54 18.3054 156.812 19.1831 156.81 20.3937C156.81 21.597 156.538 22.472 155.993 23.0186C155.448 23.5652 154.573 23.8376 153.368 23.8357C152.158 23.8357 151.28 23.5634 150.735 23.0186C150.191 22.4738 149.918 21.5989 149.918 20.3937C149.918 19.1831 150.191 18.3054 150.735 17.7607C151.28 17.2159 152.158 16.9435 153.368 16.9435ZM151.766 18.724C151.433 19.0726 151.267 19.6274 151.267 20.3883C151.267 21.1491 151.433 21.7027 151.766 22.0489C152.099 22.3939 152.633 22.5664 153.368 22.5664C154.104 22.5664 154.638 22.3939 154.971 22.0489C155.303 21.7027 155.47 21.1491 155.47 20.3883C155.471 19.6274 155.304 19.0726 154.971 18.724C154.639 18.3753 154.104 18.201 153.368 18.201C152.632 18.201 152.098 18.3753 151.766 18.724Z"
      fill="#00785F"
    />
    <path
      d="M158.834 25.5588H157.385V22.6137H158.347C158.547 22.4607 158.71 22.1228 158.837 21.6002C158.963 21.0775 159.061 20.1078 159.13 18.6909L159.223 16.9435H164.831V22.6108H166V25.556H164.541V23.9313H158.834V25.5588ZM163.376 22.6137V18.2612H160.582L160.562 18.7703C160.472 20.5945 160.254 21.8757 159.91 22.6137H163.376Z"
      fill="#00785F"
    />
  </svg>
</template>
