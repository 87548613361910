<template>
  <div class="reset-success-container">
    <div class="reset-success-section">
      <EnvelopeIcon class="section-icon" />
      <h2 class="section-title">
        {{ t('Пароль успішно скинутий') }}
      </h2>
      <p class="section-description">
        {{
          t(
            'Ми вам надіслали листа на електронну пошту з відповідними інструкціями'
          )
        }}
      </p>
      <button class="next-button" @click="goTo('passwordChangeSuccess')">
        {{ t('Продовжити') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { EnvelopeIcon } from '@heroicons/vue/24/outline'
const { t } = useI18n()

const goTo = inject('goTo')
</script>

<style lang="postcss" scoped>
.reset-success-container {
  @apply flex p-3 w-full h-full md:w-[623px];
}
.reset-success-section {
  @apply flex flex-col items-center m-auto gap-4 max-w-[290px] md:gap-5 md:max-w-[267px];
}
.section-icon {
  @apply text-orange h-12 md:h-7;
}
.section-title {
  @apply text-xl font-bold;
}
.section-description {
  @apply text-xs text-center;
}
.next-button {
  @apply btn btn-primary text-xs uppercase px-6 py-3 w-full md:w-auto;
}
</style>
