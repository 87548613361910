<template>
  <svg
    width="60"
    height="22"
    viewBox="0 0 60 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1803_24577)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.6 6.83636C17.6638 6.81602 17.7477 6.8567 17.8317 6.96855C17.8317 6.96855 17.8317 6.96855 21.6266 10.7174C21.8482 10.9411 21.8482 11.2801 21.6266 11.4462C21.6266 11.4462 21.6266 11.4462 17.8317 15.2527C17.7477 15.3646 17.6638 15.3917 17.6 15.3578C17.5362 15.3239 17.4959 15.2256 17.4959 15.0832V7.08041C17.4959 6.94144 17.5362 6.8567 17.6 6.83636Z"
        fill="#EE3131"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.1084 0.589355H11.377L11.6356 0.697823C11.6356 0.697823 11.6356 0.697822 15.5413 4.61618C15.7092 4.83989 15.6521 5.00937 15.3734 5.00937C15.3734 5.00937 15.3734 5.00937 13.7547 5.00937C13.476 5.00937 13.2543 5.23309 13.2543 5.51442C13.2543 5.51442 13.2543 5.51442 13.2543 8.42268C13.2543 8.70402 13.0293 8.92773 12.6935 8.92773C12.6935 8.92773 12.6935 8.92773 9.84902 8.92773C9.57028 8.92773 9.34527 8.70402 9.34527 8.42268C9.34527 8.42268 9.34527 8.42269 9.34527 5.51442C9.34527 5.23309 9.12362 5.00937 8.84153 5.00937H7.11201C6.83327 5.00937 6.77618 4.83989 6.94409 4.61618C6.94409 4.61618 6.94409 4.61618 10.8531 0.697823L11.1084 0.589355Z"
        fill="#EE3131"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.98956 6.75162C5.06009 6.78552 5.10375 6.88381 5.10375 7.02618V15.1951C5.10375 15.3374 5.06009 15.4222 4.98956 15.4493C4.9224 15.4764 4.82501 15.4493 4.71083 15.3646C4.71083 15.3646 4.71083 15.3646 0.858863 11.4462C0.637215 11.2801 0.637215 10.9411 0.858863 10.7174C0.858863 10.7174 0.858863 10.7174 4.71083 6.8567C4.82501 6.74484 4.9224 6.71772 4.98956 6.75162Z"
        fill="#EE3131"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.84902 13.1817C9.84902 13.1817 9.84902 13.1817 12.6935 13.1817C13.0293 13.1817 13.2543 13.4054 13.2543 13.6867C13.2543 13.6867 13.2543 13.6867 13.2543 16.7645C13.2543 17.1 13.476 17.3237 13.7547 17.3237H15.2626C15.5413 17.3237 15.6521 17.4898 15.4305 17.6593C15.4305 17.6593 15.4305 17.6593 11.6356 21.52C11.5214 21.6319 11.3838 21.6895 11.2427 21.6895C11.105 21.6895 10.964 21.6319 10.8531 21.52C10.8531 21.52 10.8531 21.52 7.05827 17.6593C6.83327 17.4898 6.94409 17.3237 7.22283 17.3237C7.22283 17.3237 7.22283 17.3237 8.84153 17.3237C9.12362 17.3237 9.34527 17.1 9.34527 16.7645C9.34527 16.7645 9.34527 16.7645 9.34527 13.6867C9.34527 13.4054 9.57028 13.1817 9.84902 13.1817Z"
        fill="#EE3131"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.0679 3.97367C27.0679 3.97367 27.0679 3.97367 28.2883 3.97367C28.733 3.97367 28.9571 4.19738 28.9571 4.70243C28.9571 4.70243 28.9571 4.70243 28.9571 6.49213C28.9571 6.49213 28.9571 6.49213 31.3478 6.49213C31.3478 6.49213 31.3478 6.49213 31.3478 4.70243C31.3478 4.19738 31.5685 3.97367 32.0701 3.97367C32.0701 3.97367 32.0701 3.97367 33.2371 3.97367C33.2371 3.97367 33.2371 3.97367 33.2371 5.14986C33.2371 5.14986 33.2371 5.14986 32.7388 5.14986L32.682 5.20748C32.682 5.20748 32.682 5.20748 32.682 10.2444C32.682 10.2444 32.682 10.2444 31.3478 10.2444C31.3478 10.2444 31.3478 10.2444 31.3478 7.72594C31.3478 7.72594 31.3478 7.72594 28.9571 7.72594C28.9571 7.72594 28.9571 7.72594 28.9571 10.2444C28.9571 10.2444 28.9571 10.2444 27.6229 10.2444C27.6229 10.2444 27.6229 10.2444 27.6229 5.20748C27.6229 5.20748 27.6229 5.14986 27.5661 5.14986C27.5661 5.14986 27.5661 5.14986 27.0679 5.14986C27.0679 5.14986 27.0679 5.14986 27.0679 3.97367Z"
        fill="#ED1C24"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36.7379 3.86182L36.768 3.86521V5.15664L36.7379 5.14986C35.7382 5.14986 34.9591 5.99048 34.9591 7.05481C34.9591 8.17337 35.7382 9.06822 36.7379 9.06822L36.768 9.06144V10.3529L36.7379 10.3563C34.9591 10.3563 33.5146 8.90213 33.5146 7.05481C33.5146 5.26172 34.9591 3.86182 36.7379 3.86182Z"
        fill="#ED1C24"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36.768 3.86521L37.3866 3.92622C38.8545 4.21772 39.9613 5.48543 39.9613 7.0548C39.9613 8.66825 38.8545 9.98679 37.3866 10.2885L36.768 10.3529V9.06144L37.4468 8.9123C38.1022 8.60723 38.5736 7.89203 38.5736 7.0548C38.5736 6.25486 38.1022 5.58372 37.4468 5.299L36.768 5.15664V3.86521Z"
        fill="#ED1C24"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M40.2388 3.97367C40.2388 3.97367 40.2388 3.97367 42.1013 3.97367H42.9907V5.14986H42.9071C42.7968 5.14986 42.5728 5.14986 42.128 5.14986C42.128 5.14986 42.128 5.14986 42.128 6.4379C42.128 6.4379 42.128 6.4379 42.5059 6.4379H42.9907V7.55646H42.7065C42.5661 7.55646 42.3788 7.55646 42.128 7.55646C42.128 7.55646 42.128 7.55646 42.128 8.95636C42.128 9.01398 42.128 9.01398 42.1849 9.01398C42.1849 9.01398 42.1849 9.01398 42.5828 9.01398H42.9907V10.2444H42.8737C42.6731 10.2444 42.2685 10.2444 41.4626 10.2444C41.0179 10.2444 40.7972 10.0207 40.7972 9.51564V5.14986C40.7972 5.14986 40.7972 5.14986 40.2388 5.14986C40.2388 5.14986 40.2388 5.14986 40.2388 3.97367Z"
        fill="#ED1C24"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M42.9907 3.97367H43.0175C44.2413 3.97367 44.9635 4.64481 44.9635 5.65491C44.9635 6.26842 44.686 6.66161 44.4085 6.94295C44.91 7.16666 45.2411 7.72594 45.2411 8.33946C45.2411 9.51564 44.3516 10.2444 43.0743 10.2444C43.0743 10.2444 43.0743 10.2444 43.0509 10.2444H42.9907V9.01398H43.1278C43.5725 9.01398 43.8534 8.73265 43.8534 8.28522C43.8534 7.8378 43.5725 7.55646 43.1278 7.55646C43.1278 7.55646 43.1278 7.55646 43.0041 7.55646H42.9907V6.4379H43.0175C43.4087 6.4379 43.6294 6.15656 43.6294 5.76676C43.6294 5.37357 43.4087 5.14986 43.0175 5.14986C43.0175 5.14986 43.0175 5.14986 43.0041 5.14986H42.9907V3.97367Z"
        fill="#ED1C24"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M47.799 3.97367C47.799 3.97367 47.799 3.97367 48.3608 3.97367H48.4644V5.76676C48.4109 5.87862 48.3541 6.04471 48.3541 6.21419C48.3541 6.21419 48.3541 6.21419 47.9094 7.50223C47.9094 7.50223 47.9093 7.50223 48.3808 7.50223H48.4644V8.67503H48.1635C47.9929 8.67503 47.799 8.67503 47.5783 8.67503C47.5783 8.67503 47.5783 8.67502 47.1871 9.68512C47.0768 10.0749 46.8561 10.2444 46.4114 10.2444C46.4114 10.2444 46.4114 10.2444 45.5754 10.2444C45.5754 10.2444 45.5754 10.2444 45.5754 9.01398C45.5754 9.01398 45.5754 9.01398 45.853 9.01398C45.9666 9.01398 45.9666 9.01398 46.0201 8.95636C46.0201 8.95636 46.0201 8.95636 47.799 3.97367Z"
        fill="#ED1C24"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M48.4644 3.97367H49.1332C49.1332 3.97367 49.1332 3.97367 50.912 8.95636C50.9655 9.01398 50.9655 9.01398 51.0759 9.01398C51.0759 9.01398 51.0759 9.01398 51.2999 9.01398C51.2999 9.01398 51.2999 9.01398 51.2999 10.2444C51.2999 10.2444 51.2999 10.2444 50.5208 10.2444C50.0761 10.2444 49.8554 10.0749 49.7417 9.6275C49.7417 9.6275 49.7417 9.6275 49.3538 8.67503C49.3538 8.67503 49.3538 8.67503 48.6048 8.67503H48.4644V7.50223H49.0195C49.0195 7.50223 49.0195 7.50223 48.5781 6.21419C48.5781 6.04471 48.5213 5.87862 48.4644 5.76676V3.97367Z"
        fill="#ED1C24"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.0679 12.0341C27.0679 12.0341 27.0679 12.0341 31.9029 12.0341C32.3476 12.0341 32.6252 12.2578 32.6252 12.7629C32.6252 12.7629 32.6252 12.7629 32.6252 17.0168C32.6252 17.071 32.6252 17.071 32.682 17.071C32.682 17.071 32.682 17.071 33.1267 17.071C33.1267 17.071 33.1267 17.071 33.1267 18.3048C33.1267 18.3048 33.1267 18.3048 31.9598 18.3048C31.4582 18.3048 31.2375 18.0811 31.2375 17.5761C31.2375 17.5761 31.2375 17.5761 31.2375 13.2679C31.2375 13.2679 31.2375 13.2103 31.1807 13.2103C31.1807 13.2103 31.1807 13.2103 28.9571 13.2103C28.9571 13.2103 28.9571 13.2103 28.9571 18.3048C28.9571 18.3048 28.9571 18.3048 27.6229 18.3048C27.6229 18.3048 27.6229 18.3048 27.6229 13.2103C27.6229 13.2103 27.6229 13.2103 27.0679 13.2103C27.0679 13.2103 27.0679 13.2103 27.0679 12.0341Z"
        fill="#ED1C24"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36.6276 11.9222L36.6544 11.9256V13.2171L36.6276 13.2103C35.6245 13.2103 34.8487 14.0509 34.8487 15.1152C34.8487 16.2338 35.6245 17.1286 36.6276 17.1286L36.6544 17.1219V18.4133L36.6276 18.4167C34.8487 18.4167 33.4577 16.9592 33.4577 15.1152C33.4577 13.3221 34.8487 11.9222 36.6276 11.9222Z"
        fill="#ED1C24"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36.6544 11.9256L37.293 11.9867C38.7877 12.2782 39.851 13.5459 39.851 15.1152C39.851 16.7287 38.7877 18.0472 37.293 18.3489L36.6544 18.4133V17.1219L37.3599 16.9727C38.0253 16.6677 38.4633 15.9525 38.4633 15.1152C38.4633 14.3153 38.0253 13.6442 37.3599 13.3594L36.6544 13.2171V11.9256Z"
        fill="#ED1C24"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M40.1285 12.0341C40.1285 12.0341 40.1285 12.0341 41.3523 12.0341C41.797 12.0341 42.0177 12.2578 42.0177 12.7629C42.0177 12.7629 42.0177 12.7629 42.0177 17.0168C42.0177 17.071 42.0177 17.071 42.0745 17.071C42.0745 17.071 42.0745 17.071 43.6862 17.071C43.6862 17.071 43.6862 17.071 43.6862 12.0341C43.6862 12.0341 43.6862 12.0341 45.0204 12.0341C45.0204 12.0341 45.0204 12.0341 45.0204 17.071C45.0204 17.071 45.0204 17.071 46.632 17.071C46.6889 17.071 46.6889 17.071 46.6889 17.0168C46.6889 17.0168 46.6889 17.0168 46.6889 12.7629C46.6889 12.2578 46.9096 12.0341 47.3543 12.0341C47.3543 12.0341 47.3543 12.0341 48.5781 12.0341C48.5781 12.0341 48.5781 12.0341 48.5781 13.2103C48.5781 13.2103 48.5781 13.2103 48.0765 13.2103C48.0197 13.2103 48.0197 13.2679 48.0197 13.2679C48.0197 13.2679 48.0197 13.2679 48.0197 17.5761C48.0197 18.0811 47.799 18.3048 47.3543 18.3048C47.3543 18.3048 47.3543 18.3048 41.4058 18.3048C40.9076 18.3048 40.6835 18.0811 40.6835 17.5761C40.6835 17.5761 40.6835 17.5761 40.6835 13.2679C40.6835 13.2679 40.6835 13.2103 40.63 13.2103C40.63 13.2103 40.63 13.2103 40.1285 13.2103C40.1285 13.2103 40.1285 13.2103 40.1285 12.0341Z"
        fill="#ED1C24"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M49.6314 12.0341C49.6314 12.0341 49.6314 12.0341 53.801 12.0341C54.3026 12.0341 54.4697 12.2578 54.4697 12.7052C54.4697 12.7052 54.4697 12.7052 54.4697 13.7696H53.2459C53.2459 13.7696 53.2459 13.7696 53.2459 13.2679C53.2459 13.2103 53.2459 13.2103 53.1891 13.2103C53.1891 13.2103 53.1891 13.2103 52.4134 13.2103C52.4134 13.2103 52.4134 13.2103 52.4134 18.3048C52.4134 18.3048 52.4134 18.3048 51.0759 18.3048C51.0759 18.3048 51.0759 18.3048 51.0759 13.2103C51.0759 13.2103 51.0759 13.2103 50.3001 13.2103C50.2433 13.2103 50.2433 13.2103 50.2433 13.2679C50.2433 13.2679 50.2433 13.2679 50.2433 13.7696C50.2433 13.7696 50.2433 13.7696 49.0195 13.7696C49.0195 13.7696 49.0195 13.7696 49.0195 12.7052C49.0195 12.2578 49.1867 12.0341 49.6314 12.0341Z"
        fill="#ED1C24"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M56.1918 12.0341C56.1918 12.0341 56.1918 12.0341 56.7535 12.0341H56.8572V13.8272C56.8003 13.9391 56.7468 14.1051 56.7468 14.2746C56.7468 14.2746 56.7468 14.2746 56.3021 15.5627C56.3021 15.5627 56.3021 15.5627 56.7702 15.5627H56.8572V16.7355H56.5562C56.3857 16.7355 56.1918 16.7355 55.9677 16.7355C55.9677 16.7355 55.9677 16.7355 55.5799 17.6879C55.4695 18.1354 55.2455 18.3048 54.8008 18.3048C54.8008 18.3048 54.8008 18.3048 53.9682 18.3048C53.9682 18.3048 53.9682 18.3048 53.9682 17.071C53.9682 17.071 53.9682 17.071 54.2457 17.071C54.3594 17.071 54.3594 17.071 54.4129 17.0168C54.4129 17.0168 54.4129 17.0168 56.1918 12.0341Z"
        fill="#ED1C24"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M56.8572 12.0341H57.5226C57.5226 12.0341 57.5226 12.0341 59.3048 17.0168C59.3583 17.071 59.3583 17.071 59.472 17.071H59.6927C59.6927 17.071 59.6927 17.071 59.6927 18.3048C59.6927 18.3048 59.6927 18.3048 58.9136 18.3048C58.4689 18.3048 58.2482 18.1354 58.1345 17.6879C58.1345 17.6879 58.1345 17.6879 57.7466 16.7355C57.7466 16.7355 57.7466 16.7355 56.9976 16.7355H56.8572V15.5627H57.4122C57.4122 15.5627 57.4122 15.5627 56.9675 14.2746C56.9675 14.1051 56.914 13.9391 56.8572 13.8272V12.0341Z"
        fill="#ED1C24"
      />
    </g>
    <defs>
      <clipPath id="clip0_1803_24577">
        <rect
          width="59"
          height="21.1002"
          fill="white"
          transform="translate(0.692627 0.589355)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
