<template>
  <SwitchGroup :key="key" as="div" class="flex items-center">
    <SwitchLabel as="span" class="mr-[5px] text-xs">
      <span
        :class="[
          'text-gray-mud cursor-pointer uppercase',
          enabled ? 'text-gray-mud' : 'text-green-ibis'
        ]"
      >{{ t('Зброя') }}</span>
    </SwitchLabel>
    <Switch
      :key="key"
      :model-value="enabled"
      :class="[
        'relative inline-flex h-[15px] w-7 flex-shrink-0 cursor-pointer rounded-full border-px border-transparent transition-colors duration-200 ease-in-out focus:outline-none p-[1px]',
        enabled ? 'bg-primary' : 'bg-green-ibis'
      ]"
      @update:model-value="switchSite"
    >
      <span
        aria-hidden="true"
        :class="[
          enabled ? 'translate-x-[13px]' : 'translate-x-0',
          'pointer-events-none inline-block h-[13px] w-[13px] transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        ]"
      />
    </Switch>
    <SwitchLabel as="span" class="ml-[5px] text-xs">
      <span
        :class="[
          'text-gray-mud  cursor-pointer uppercase',
          enabled ? 'text-primary' : ''
        ]"
      >{{ t('Рибалка') }}</span>
    </SwitchLabel>
  </SwitchGroup>
</template>

<script setup>
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
const key = ref('')
const { t } = useI18n()
const storeType = useStoreType()
const enabled = computed(() => storeType.isFishing)

const switchSite = () => {
  return enabled.value
    ? storeType.switchToHunting()
    : storeType.switchToFishing()
}
// watch(
//   () => enabled.value,
//   () => {
//     key.value = Date.now().toString()
//   },
//   {
//     immediate: true
//   }
// )
// onMounted(() => {
//   key.value = Date.now().toString()
// })
</script>
