<template>
  <Menu
    as="div"
    class="relative text-left z-[200]"
    @click.stop="onClick"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <div>
      <MenuButton
        class="inline-flex w-full justify-center gap-x-1.5 px-2.5 py-1 text-sm font-semibold items-center link text-gray-mud sm:text-green-swamp rounded-md ring-gray-300 ring-1 sm:ring-0 pointer-events-none"
        :aria-label="t('Контакти')"
      >
        <PhoneIcon class="h-[14px] md:h-4 w-[14px] md:w-4" />
        <span class="hidden sm:inline"> {{ hotlinePhoneNumber }}</span>
        <IconsDropdownChevron :active="open" />
      </MenuButton>
    </div>
    <app-transitions-dropdown>
      <div v-if="open">
        <MenuItems
          static
          class="absolute right-0 z-10 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div class="py-[26px] px-[30px] gap-4 flex flex-col">
            <p class="text-sm">
              {{ t('Гаряча лінія') }}
            </p>
            <AppHeaderContactsMenuBlock />
            <MenuItem>
              <button
                type="button"
                class="btn btn-primary whitespace-nowrap uppercase text-[11px] w-full py-3.5 px-[15px] leading-3"
                @click="handleCallBack"
              >
                {{ t('Зворотній зв’язок') }}
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </div>
    </app-transitions-dropdown>

    <AppBackdrop :show="open" @on-click="() => (open = false)" />
  </Menu>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { PhoneIcon } from '@heroicons/vue/24/solid'
import AppTransitionsDropdown from '../transitions/Dropdown.vue'
import { useSettingsStore } from '~/stores/settings'

const { t } = useI18n()
const { hotlinePhoneNumber } = storeToRefs(useSettingsStore())

const callBackDialog = inject('callBackDialog')

const handleCallBack = () => {
  callBackDialog.value?.open()
}
const onMouseEnter = e => {
  // ignore on touch
  if (e?.sourceCapabilities?.firesTouchEvents) {
    return true
  }
  open.value = true
}
const onMouseLeave = () => {
  open.value = false
}
const onClick = () => {
  open.value = !open.value
}
const open = ref(false)
</script>
