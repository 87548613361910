<template>
  <div>
    <div class="mb-4">
      <div>
        <a
          :href="`tel:${hotlinePhoneNumber.replaceAll(' ', '')}`"
          class="tel-link"
        >
          <PhoneIcon class="tel-link--icon" />
          <span
            class="tel-link--number"
            :class="largerMobile ? 'text-2xl md:text-[15px]' : 'text-[15px]'"
          >
            {{ hotlinePhoneNumber }}
          </span>
        </a>
      </div>
      <div class="py-[26px] gap-4 flex flex-col">
        <p class="text-sm">
          {{ t('Полювання і зброя') }}
        </p>
      </div>
      <template v-if="weaponPhoneNumbers.length">
        <a
          v-for="phone in weaponPhoneNumbers"
          :key="phone.propertyValue"
          :href="`tel:${phone.propertyValue.replaceAll(' ', '')}`"
          class="tel-link"
          :class="largerMobile ? 'text-xl md:text-sm' : 'text-sm'"
        >
          <component
            :is="icons[phone.propertyName] || icons.default"
            class="tel-link--icon"
          />
          <span class="tel-link--number"> {{ phone.propertyValue }} </span>
        </a>
      </template>
      <div class="py-[26px] gap-4 flex flex-col">
        <p class="text-sm">
          {{ t('Риболовля та туризм') }}
        </p>
      </div>
      <template v-if="fishingPhoneNumbers.length">
        <a
          v-for="phone in fishingPhoneNumbers"
          :key="phone.propertyValue"
          :href="`tel:${phone.propertyValue.replaceAll(' ', '')}`"
          class="tel-link"
          :class="largerMobile ? 'text-xl md:text-sm' : 'text-sm'"
        >
          <component
            :is="icons[phone.propertyName] || icons.default"
            class="tel-link--icon"
          />
          <span class="tel-link--number"> {{ phone.propertyValue }} </span>
        </a>
      </template>
    </div>
    <div
      class="mb-4 text-xs leading-6 whitespace-pre-wrap"
      v-html="openingHours"
    ></div>
    <AppContactsMessengersBlock :larger-mobile="largerMobile" />
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { PhoneIcon } from '@heroicons/vue/24/solid'
import { useSettingsStore } from '~/stores/settings'
const { t } = useI18n()
defineProps({
  largerMobile: {
    type: Boolean,
    default: false
  }
})

const {
  openingHours,
  weaponPhoneNumbers,
  fishingPhoneNumbers,
  hotlinePhoneNumber
} = storeToRefs(useSettingsStore())

const icons = shallowRef({
  default: PhoneIcon,
  phone_lifecell: resolveComponent('IconsMobileLifecell'),
  phone_vodafone: resolveComponent('IconsMobileVodafone'),
  phone_kyivstar: resolveComponent('IconsMobileKyivstar')
})
</script>

<style lang="postcss" scoped>
.tel-link {
  @apply link flex items-center py-1 w-fit;
}
.tel-link--icon {
  @apply h-4 w-4 text-green-ibis;
}
.tel-link--number {
  @apply font-bold ml-[9px] whitespace-nowrap;
}
</style>
