export default function () {
  const { siteEnv, metaPixelCodeId } = useRuntimeConfig().public

  useHead({
    script: [
      { src: 'https://connect.facebook.net/uk_UA/sdk.js', async: true },
      {
        key: 'esputnikTrackerScript',
        innerHTML: `
          !function (t, e, c, n) {
        var s = e.createElement(c);
        s.async = 1, s.src = 'https://statics.esputnik.com/scripts/' + n + '.js';
        var r = e.scripts[0];
        r.parentNode.insertBefore(s, r);
        var f = function () {
            f.c(arguments);
        };
        f.q = [];
        f.c = function () {
            f.q.push(arguments);
        };
        t['eS'] = t['eS'] || f;
        }(window, document, 'script', '${
          siteEnv === 'production' ? '4FD0C8C92CE24C27BCC52C239F112BF3' : ''
        }');
        eS('init', {
           TRACKING: false,
           RECOMS: true
        });
        `,
        type: 'text/javascript',
        tagPosition: 'bodyClose'
      },
      {
        key: 'lookSizeScript',
        innerHTML: `
              (function(w,d,s){
                w['LS']=w['LS']||{};
                LS.api_key='89763092fa396a929eb43895fcc';
                var f=d.getElementsByTagName(s)[0], j=d.createElement(s);
                j.src='https://www.looksize.com/get_ls_js.php?k='+LS.api_key;
                j.async=true;
                f.parentNode.insertBefore(j,f);
              })(window,document,'script');
            `,
        type: 'text/javascript',
        tagPosition: 'bodyClose'
      },
      {
        key: 'metaPixelCodeScript',
        innerHTML: metaPixelCodeId
          ? `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${metaPixelCodeId}');
          fbq('track', 'PageView');
        `
          : '',
        tagPosition: 'head'
      }
    ],
    noscript: [
      {
        key: 'metaPixelCodeNoScript',
        innerHTML: metaPixelCodeId
          ? `<img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=${metaPixelCodeId}&ev=PageView&noscript=1"
          />`
          : '',
        tagPosition: 'bodyOpen'
      }
    ]
  })

  const { multisearchTracker } = useRuntimeConfig().public

  if (multisearchTracker && import.meta.client) {
    if (!window?.dataLayer) {
      window.dataLayer = []
    }
    useHead({
      script: [
        {
          key: 'multisearchTrackerScript',
          innerHTML: `
                  (function(d) {
                      var s = d.createElement('script');
                      s.defer = true;
                      s.src = 'https://tracker.multisearch.io/3894dae9a35f';
                      if (d.head) d.head.appendChild(s);
                  })(document);
                `,
          tagPosition: 'head'
        }
      ]
    })
  }
}
